<template>
  <div>
    <!-- 公共头部 -->
   <Header></Header>
    <template v-if="problem.length">
      <div class="view1">
        <div class="view1-3">
          <div class="alert-view1">
            <span class="type">{{ problem[curIndex].type }}</span>
            <div class="question_title">
              {{ curIndex + 1 }}/{{ total }}、{{ problem[curIndex].title }}
            </div>
          </div>
          <!-- <div class="img_wrap">
						<img src="https://tanpei.ks265.net//Public/uploads/images/2021-05-12/609b31b3cb01c.png" alt="">

						</div> -->
          <div class="img_wrap">
            <img
              class="img"
              v-show="problem[curIndex].img"
              :src="problem[curIndex].img"
            />
          </div>
          <div class="alert-view2 clearfix">
            <li
              class="clearfix"
              :class="[problem[curIndex].p_type == 2 ? 'active' : '']"
              v-for="(item, index) in problem[curIndex].answer"
            >
              <label
                class="radio fl"
                v-if="
                  problem[curIndex].type == '单选题' ||
                  problem[curIndex].type == '判断题'
                "
              >
                <input
                  class="input_radio"
                  type="radio"
                  :value="item.option"
                  :disabled="problem[curIndex].disabled"
                  v-model="problem[curIndex].checkedValue"
                  @change="submit"
                />
                <span></span>
                <a v-if="problem[curIndex].p_type == 1"
                  >{{ item.option }}、{{ item.title }}</a
                >
                <a v-if="problem[curIndex].p_type == 2">{{ item.option }}、</a>
                <div class="img-box" v-if="problem[curIndex].p_type == 2">
                  <img class="img" :src="item.img" />
                </div>
              </label>
              <label
                class="checkbox fl"
                v-if="problem[curIndex].type == '多选题'"
              >
                <input
                  class="input_checkbox"
                  type="checkbox"
                  :value="item.option"
                  :disabled="problem[curIndex].disabled"
                  v-model="problem[curIndex].checkVal"
                />
                <span></span>
                <a v-if="problem[curIndex].p_type == 1"
                  >{{ item.option }}、{{ item.title }}</a
                >
                <a v-if="problem[curIndex].p_type == 2">{{ item.option }}、</a>
                <div class="img-box" v-if="problem[curIndex].p_type == 2">
                  <img class="img" :src="item.img" />
                </div>
              </label>
            </li>
            <div
              class="submit"
              v-if="
                problem[curIndex].type == '多选题' &&
                !problem[curIndex].disabled
              "
              @click="submit"
            >
              确定
            </div>
          </div>
        </div>
        <div class="view1-4">
          <div class="btn btn1" @click="prev">上一题</div>

          <div class="btn" v-if="showDTK" @click="showDTK = false">
            收起答题卡
          </div>
          <div class="btn" v-else-if="!showDTK" @click="showDTK = true">
            显示答题卡
          </div>
          <div class="btn" v-if="showJX" @click="showJX = false">收起解析</div>
          <div class="btn" v-else-if="!showJX" @click="showJX = true">
            显示解析
          </div>

          <div class="btn btn1" @click="next">下一题</div>
        </div>
        <div class="view1-2">
          <input
            type="checkbox"
            id="checkbox"
            class="checkbox"
            v-model="autoNext"
          />
          <label class="label" for="checkbox">答对自动下一题</label>
        </div>
      </div>

      <div class="view2" v-show="problem[curIndex].status != 0">
        <div class="singlepage" v-if="problem[curIndex].status == 1">
          <font color="blue">【回答正确】</font>
        </div>
        <div class="singlepage" v-if="problem[curIndex].status == 2">
          <font color="red">【回答错误】</font>正确答案：{{
            problem[curIndex].daan
          }}
        </div>
      </div>

      <div class="view2" v-if="showJX">
        <div class="cate-title">本题详解</div>
        <div
          class="singlepage"
          v-html="problem[curIndex].analysis || '暂无解析'"
        ></div>
      </div>

      <div class="mask" v-if="showDTK" @click="showDTK = false"></div>
      <div class="blank_100"></div>
      <div class="tools-box">
        <div class="tools">
          <!-- 收藏 -->
          <template v-if="$route.query.type == 3">
            <div class="shoucang-box" @click="removes(problem[curIndex].id, 3)">
              <img class="icon" src="@/assets/images/icon102.png" />
              <span class="text">移除</span>
            </div>
          </template>
          <template v-else>
            <div
              class="shoucang-box"
              v-if="problem[curIndex].is_shoucang == 0"
              @click="shoucang(problem[curIndex].id, 2)"
            >
              <img class="icon" src="@/assets/images/icon97.png" />
              <span class="text">收藏</span>
            </div>
            <div
              class="shoucang-box"
              v-if="problem[curIndex].is_shoucang == 1"
              @click="removes(problem[curIndex].id, 4)"
            >
              <img class="icon" src="@/assets/images/icon98.png" />
              <span class="text">取消收藏</span>
            </div>
          </template>
          <!-- 收藏 -->

          <!-- 数字盒子 -->
          <div class="num-box">
            <div class="item">{{ correctNum }}</div>
            <div class="item">{{ errorNum }}</div>
            <div class="item">{{ curIndex + 1 }}/{{ total }}</div>
          </div>
          <!-- 数字盒子 -->
        </div>
        <div class="view3" v-show="showDTK">
          <ul class="list clearfix">
            <li
              class="item fl"
              v-for="(item, index) in problem"
              @click="curIndex = index"
              :class="[
                curIndex == index ? 'green' : '',
                item.status == 1 ? 'blue' : '',
                item.status == 2 ? 'red' : '',
              ]"
            >
              {{ index + 1 }}
            </li>
          </ul>
          <div class="close-btn">
            <div class="loadMore" @click="loadMore" v-if="loading">
              加载更多题目
            </div>
            <div class="loadMore active" v-else>已全部加载</div>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="no-list">
      <img class="icon" src="@/assets/images/icon103.png" />
      <div class="text">暂时还没有任何题目哦~</div>
      <div
        class="btn"
        @click="
          $router.replace({
            path: '/simulation/choose',
            query: { student_id: $route.query.student_id },
          })
        "
      >
        返回上一层
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      subject_title: "",
      problem: [],
      limit: 150,
      page: 1,
      total: 0,
      curIndex: 0,
      showDTK: false,
      showJX: false,
      correctNum: 0,
      errorNum: 0,
      missNum: 0,
      autoNext: true,
      loading: true,
    };
  },
  watch: {
    page() {
      if (this.$route.path == "/simulation/simulation") {
        this.getTika();
      }
    },
  },
  computed: {
    uid() {
    	return  this.$route.query.uid;
    },
    title() {
      var type = this.$route.query.type;
      if (type == 1) return "顺序练习";
      if (type == 2) return "随机练习";
      if (type == 3) return "错题练习";
      if (type == 4) return "收藏练习";
    },
    accuracy() {
      if (this.correctNum + this.errorNum != 0) {
        return (
          (this.correctNum / (this.correctNum + this.errorNum)) *
          100
        ).toFixed(2);
      } else {
        return 0;
      }
    },
  },
  activated() {
    this.getUserInfo();
  },
  deactivated() {
    this.subject_title = "";
    this.problem = [];
    this.page = 1;
    this.total = 0;
    this.curIndex = 0;
    this.showDTK = false;
    this.showJX = false;
    this.correctNum = 0;
    this.errorNum = 0;
    this.missNum = 0;
    this.autoNext = true;
    this.loading = true;
  },
  methods: {
    // 加载更多
    loadMore() {
      if (this.loading) {
        this.page = ++this.page;
      }
    },
    // 移除错题&收藏
    async removes(problem_id, type) {
      var student_id = this.$route.query.student_id;
      var uid = this.$store.state.userInfo.id;
      var data = {
        problem_id,
        student_id,
        type,
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/study/removes", data);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "模拟考试 移除错题&收藏：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        if (type == 1 || type == 2) {
          this.problem[this.curIndex].is_shoucang = 0;
        } else {
          var problem = this.problem;
          problem.splice(this.curIndex, 1);
          this.total = this.total - 1;
        }
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 收藏或添加错题
    async shoucang(problem_id, type) {
      if (!problem_id) {
        return false;
      }
      var student_id = this.$route.query.student_id;
      var uid = this.uid;
      var data = {
        problem_id,
        student_id,
        type,
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/study/shoucang", data);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "模拟考试 收藏或添加错题：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        if (type == 2) {
          this.problem[this.curIndex].is_shoucang = 1;
        }
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 提交答案
    submit() {
      var curIndex = this.curIndex;
      var problem = this.problem;
      problem[curIndex]["disabled"] = true; // 使题目不可选择
      if (
        problem[curIndex].type == "单选题" ||
        problem[curIndex].type == "判断题"
      ) {
        // 判断题目对错
        var a = problem[curIndex].checkedValue;
        var b = problem[curIndex].daan;
        if (a == b) {
          problem[curIndex].status = 1;
          this.correctNum = this.correctNum + 1;
          if (this.autoNext) {
            var that = this;
            setTimeout(function () {
              that.next();
            }, 0);
          }
        } else {
          problem[curIndex].status = 2;
          this.errorNum = this.errorNum + 1;
          // 答错执行自动添加到错题
          this.shoucang(problem[curIndex].id, 1);
        }
      }
      if (problem[curIndex].type == "多选题") {
        var a = problem[curIndex].checkVal;
        var answer = problem[curIndex].daan;
        var b = answer.split("");
        if (JSON.stringify(a.sort()) == JSON.stringify(b.sort())) {
          problem[curIndex].status = 1;
          this.correctNum = this.correctNum + 1;
          if (this.autoNext) {
            this.next();
          }
        } else {
          problem[curIndex].status = 2;
          this.errorNum = this.errorNum + 1;
          // 答错执行自动添加到错题
          this.shoucang(problem[curIndex].id, 1);
        }
      }
    },
    // 上一题
    prev() {
      if (this.curIndex != 0) {
        this.curIndex = --this.curIndex;
      } else {
        this.$toast.info("已经到达第一题");
      }
    },
    // 下一题
    next() {
      if (this.curIndex + 1 != this.problem.length) {
        this.curIndex = ++this.curIndex;
      } else if (
        this.curIndex + 1 == this.problem.length &&
        this.curIndex + 1 < this.total
      ) {
        this.page = ++this.page;
      } else {
        this.$toast.info("已经到达最后一题");
      }
    },
    // 获取题卡
    async getTika() {
      var student_id = this.$route.query.student_id;
      var type = this.$route.query.type;
      var uid = this.uid;
      var limit = this.limit;
      var page = this.page;
      var data = {
        student_id,
        type,
        uid,
        page,
        limit,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/study/tika", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "模拟考试 获取题目：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.subject_title = res.data.subject_title;
        this.total = Number(res.data.count);

        var problem = res.data.problem;
        for (var i = 0; i < problem.length; i++) {
          problem[i]["disabled"] = false;
          problem[i]["status"] = 0; // status 0初始化 1回答正确 2回答错误
          if (
            problem[i]["img"] &&
            problem[i]["img"].substring(0, 4) != "http"
          ) {
            problem[i]["img"] = this.$store.state.http + problem[i]["img"];
          }
          if (
            problem[i]["type"] == "单选题" ||
            problem[i]["type"] == "判断题"
          ) {
            problem[i]["checkedValue"] = "";
          }
          if (problem[i]["type"] == "多选题") {
            problem[i]["checkVal"] = [];
          }
          if (problem[i]["p_type"] == 2) {
            for (var j = 0; j < problem[i]["answer"].length; j++) {
              if (
                problem[i]["answer"][j]["img"] &&
                problem[i]["answer"][j]["img"].substring(0, 4) != "http"
              ) {
                problem[i]["answer"][j]["img"] =
                  this.$store.state.http + problem[i]["answer"][j]["img"];
              }
            }
          }
        }
        if (page == 1) {
          if (problem.length < limit) {
            this.problem = problem;
            this.loading = false;
          } else {
            this.problem = problem;
          }
        } else {
          var data = this.problem;
          for (let i = 0; i < problem.length; i++) {
            data.push(problem[i]);
          }
          this.problem = data;
          this.curIndex = ++this.curIndex;
        }
      } else if (code != 1 && page == 1) {
        this.problem = [];
        this.loading = false;
      } else {
        this.loading = false;
        this.$toast.info("已加载完所有题目");
      }
    },
    // 获取用户信息
    async getUserInfo() {
      if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return ;
        }
      		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.getTika();
      }
    },
  },
};
</script>

<style scoped>
.view1 {
  background: #ffffff;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.view1 .view1-3 {
  position: relative;
}
.view1 .view1-3 .alert-view1 {
  position: relative;
  padding-left: 1.1rem;
}
.view1 .view1-3 .alert-view1 .type {
  width: 0.9rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121e5f;
  font-size: 0.24rem;
  color: #ffffff;
  position: absolute;
  border-radius: 0.06rem;
  left: 0;
  top: 0;
}
.view1 .view1-3 .alert-view1 .question_title {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.4rem;
}
.view1 .view1-3 .alert-view1 .img {
  display: block;
  margin: 0.3rem auto 0;
}
.view1 .view1-3 .alert-view2 {
  margin-top: 0.2rem;
}
.view1 .view1-3 .alert-view2 .active {
  float: left;
  width: 50%;
}
.view1 .view1-3 .alert-view2 .submit {
  width: 1.3rem;
  height: 0.6rem;
  background: #121e5f;
  color: #ffffff;
  font-size: 0.26rem;
  text-align: center;
  border-radius: 0.06rem;
  margin-top: 0.2rem;
  cursor: pointer;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view1 .view1-4 {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.view1 .view1-4 .btn {
  width: 1.6rem;
  height: 0.66rem;
  border: 0.02rem solid #d6d6d6;
  background: #ffffff;
  border-radius: 0.5rem;
  font-size: 0.24rem;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view1 .view1-4 .btn1 {
  border: 0.02rem solid #121e5f;
  background: #121e5f;
  color: #ffffff;
}

.view1 .view1-2 {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.view1 .view1-2 .checkbox {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.1rem;
}
.view1 .view1-2 .label {
  font-size: 0.26rem;
  color: #333333;
}

.view2 {
  margin-bottom: 0.3rem;
}
.view2 .cate-title {
  height: 0.88rem;
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  color: #333333;
  padding-left: 0.46rem;
  position: relative;
}
.view2 .cate-title::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.05rem;
  height: 0.26rem;
  background: #ff6000;
  left: 0.3rem;
  top: 50%;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.view2 .singlepage {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.4rem;
  padding: 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
}

.tools-box {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 999;
}
.tools-box .tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.98rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  padding: 0 0.3rem;
}
.tools-box .tools .shoucang-box {
  display: flex;
  align-items: center;
  height: 0.4rem;
}
.tools-box .tools .shoucang-box .icon {
  height: 0.24rem;
}
.tools-box .tools .shoucang-box .text {
  font-size: 0.26rem;
  color: #333333;
  margin-left: 0.16rem;
}
.tools-box .tools .num-box {
  display: flex;
  align-items: center;
}
.tools-box .tools .num-box .item {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.4rem;
  margin-right: 0.36rem;
  padding-left: 0.4rem;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 0.24rem 0.24rem;
}
.tools-box .tools .num-box .item:nth-child(1) {
  background-image: url(../../../assets/images/icon90.png);
}
.tools-box .tools .num-box .item:nth-child(2) {
  background-image: url(../../../assets/images/icon91.png);
}
.tools-box .tools .num-box .item:nth-child(3) {
  background-image: url(../../../assets/images/icon92.png);
}
.tools-box .tools .num-box .item:last-child {
  margin-right: 0;
}

.view3 {
}
.view3 .list {
  padding: 0.2rem 0.4rem;
  height: 6rem;
  overflow-y: scroll;
}
.view3 .list .item {
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  border: 0.02rem solid #333333;
  font-size: 0.32rem;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 0.38rem;
  margin-top: 0.3rem;
}
.view3 .list .item:nth-child(1),
.view3 .list .item:nth-child(2),
.view3 .list .item:nth-child(3),
.view3 .list .item:nth-child(4),
.view3 .list .item:nth-child(5),
.view3 .list .item:nth-child(6) {
  margin-top: 0;
}
.view3 .list .item:nth-child(6n) {
  margin-right: 0;
}
.view3 .list .item.green {
  border: 0.02rem solid #1d6bd0;
  background: #c8dcf7;
  color: #1d6bd0;
}
.view3 .list .item.red {
  border: 0.02rem solid #ff2828;
  background: #ffe4e4;
  color: #ff2828;
}
.view3 .list .item.blue {
  border: 0.02rem solid #009d30;
  background: #d9ffe5;
  color: #009d30;
}
.view3 .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0;
  border-top: 0.02rem solid #dddddd;
}
.view3 .close-btn .loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.5rem;
  font-size: 0.28rem;
  background: #121e5f;
  color: #ffffff;
}
.view3 .close-btn .loadMore.active {
  background: #cccccc;
}

.no-list {
  text-align: center;
  padding-top: 1.2rem;
}
.no-list .icon {
  width: 3.5rem;
  height: 2.52rem;
}
.no-list .text {
  font-size: 0.28rem;
  color: #c5c5c5;
  margin-top: 0.7rem;
}
.no-list .btn {
  width: 4rem;
  height: 0.8rem;
  background: #121e5f;
  border-radius: 0.5rem;
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem auto 0;
}
.img_wrap {
  margin: 0.2rem 0;
  text-align: center;
}
.img_wrap img {
  width: 100%;
  object-fit: contain;
}
</style>